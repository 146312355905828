/* Card section css  */
.blog-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5rem;
}

.blog-post {
  display: flex;
  align-items: center;
  margin: 1rem;
  /* height: 400px; */
  width: 80%;
  position: relative;
}

.blog-post-row {
  flex-direction: row-reverse;
}

.blog-post:nth-child(even) {
  flex-direction: row-reverse;
}

.blog-post__img {
  max-width: 60%;
  height: 25rem;
  padding: 0 2rem;
  transform: translateY(2rem);
  position: relative;
}

.blog-post-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transform: rotate(4deg);
}

.blog-post-img-rotate {
  transform: rotate(-4deg);
}

.blog-post__info {
  width: 60%;
}


/* done dynamicallhy in dynamicdiamonds */
/* .blog-post__title {
  font-size: clamp(20px, 3vw, 2rem);
  margin: 4rem 0 1rem;
  text-transform: uppercase;
  color: #f05e22;
} */

.blog-post__text {
  margin-bottom: 3rem;
  font-size: clamp(24px, 2vw, 1.5rem);
  color: rgb(0, 0, 0);
}

@media screen and (max-width: 1068px) {
  .blog-post {
    flex-direction: column;
    width: auto;
    height: auto;
  }

  .blog-post__img {
    max-width: 80%;
    height: 25rem;
    padding: 0 2rem;
    transform: translateY(-7rem);
    position: relative;
  }

  .blog-post__info {
    padding: 0 2rem;
  }

  .blog-post__title {
    margin: 0 0 1rem;
    text-transform: uppercase;
    color: black;
  }

  .blog-post__text {
    margin-bottom: 1rem;
    color: rgba(0, 0, 0, 0.7);
  }

  .blog-post__info {
    width: 100%;
  }

  .blog-post-img {
    transform: rotate(0deg);
  }
  
  .blog-post-img-rotate {
    transform: rotate(0deg);
  }
}

@media screen and (max-width: 768px) {
  .blog-container {
    padding: 0;
  }
  .blog-post__info {
    width: 100%;
  }

  .blog-post__img {
    max-width: 100%;
    height: 20rem;
    margin: 2rem 0;
    transform: translate(0);
  }
  .blog-post-img {
    transform: rotate(0deg);
  }
  
  .blog-post-img-rotate {
    transform: rotate(0deg);
  }
}

/* Card section End */