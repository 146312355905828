/* 
#e2d1bb
#353739
#CC4442
#2588C9

color pallet for website and lineup 
*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


/* new font  */

@font-face {
  font-family: Windsore;
  src: url("./components/assets/24-Fonts/Windsore/Windsore-Regular.otf");
}
 
@font-face {
  font-family: Clamiroe-regular;
  src: url("./components/assets/24-Fonts/Clamiroe/Regular/Clamiroe-Regular.otf");
}
 
@font-face {
  font-family: Clamiroe-texture;
  src: url("./components/assets/24-Fonts/Clamiroe/Texture/Clamiroe-Texture.otf");
}
 
@font-face {
  font-family: RobotoSlab;
  src: url("./components/assets/24-Fonts/RobotoSlab-Regular.ttf");
}

html {
  scroll-behavior: smooth;
}

body {
  background: #f1e5d7;         /* old color - decbb4*/ 
}

::-webkit-scrollbar {
  display: none;
}

hr {
  width: 100%;
  display: flex;
  margin: 0 auto;
  margin-bottom: 1rem;
  border: 10px solid #55a49e;
  border-style: none none double;
}

ul > li {
  font-family: RobotoSlab;
  line-height: 1.521;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Clamiroe-texture;
  text-transform: uppercase;
}

p {
  font-family: RobotoSlab;
  line-height: 1.521;
}


/* #f05e22 orange */
/* #55a49e teal */
/* cream #f4e9d8 */
/* #F15F22 new orange */
/* #F1E5D7 new cream*/


.st0 { fill: #f05e22; }
.st1 { fill: #8F3231; }
.st2 { fill: none; }
.st3 { enable-background: new; }
.st4 {
  fill: #ECE4D7;
  font-family: 'Windsore';
  font-size: 20px;
}