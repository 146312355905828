/* ************** Hover card effect ***************** */
.jane-doe-container {
  max-width: 900px;
  display: flex;
  justify-content: space-evenly;
}

.jane-doe-card-wrapper {
  width: 400px;
  height: 500px;
  position: relative;
}

.jane-doe-card {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 400px;
  transform: translate(-50%, -50%);
  overflow: hidden;
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: 0.5s;
}

.jane-doe-card .jane-doe-card-image {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #000;
  transition: 0.5s;
}

.jane-doe-card-image > img {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.jane-doe-card:hover img {
  opacity: 0.4;
  transition: 0.5s;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.jane-doe-card:hover .jane-doe-card-image {
  transition: all 0.9s;
}

/**** Social Icons *****/
.jane-doe-social-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  display: flex;
}

.jane-doe-social-icons li {
  list-style: none;
}

.jane-doe-social-icons li a {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #fff;
  font-size: 23px;
  color: #333;
  font-weight: bold;
  margin: 0 6px;
  transition: 0.4s;
  transform: translateY(200px);
  opacity: 0;
}

.jane-doe-card:hover .jane-doe-social-icons li a {
  transform: translateY(0px);
  opacity: 1;
}

.jane-doe-social-icons li a:hover {
  background: #000;
  transition: 0.2s;
}

.jane-doe-social-icons li a:hover .fab {
  color: #fff;
}

.jane-doe-social-icons li a .fab {
  transition: 0.8s;
}

.jane-doe-social-icons li a .fab:hover {
  transform: rotateY(360deg);
  color: #fff;
}

.jane-doe-card:hover li:nth-child(1) a {
  transition-delay: 0.1s;
}

.jane-doe-card:hover li:nth-child(2) a {
  transition-delay: 0.2s;
}

.jane-doe-card:hover li:nth-child(3) a {
  transition-delay: 0.3s;
}

.jane-doe-card:hover li:nth-child(4) a {
  transition-delay: 0.4s;
}

/**** Personal Details ****/
.jane-doe-details h2 {
  padding: 0;
  text-align: center;
  z-index: 4;
  font-size: 1rem;
}

.jane-doe-details h2 .jane-doe-job-title {
  font-size: 1rem;
  line-height: 2.5rem;
  color: rgb(117, 116, 116);
  font-weight: 300;
}

.jane-doe-jane {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 2;
  background: #fff;
  width: 100%;
  z-index: 3;
  padding: 10px;
  background: #f05e22;
}

.jane-doe-profile-two .jane-doe-social-icons li a {
  border-radius: 50%;
}

/* .jane-doe-card:hover .jane-doe-profile-img--two {
  transform: rotateY(180deg);
} */

/* *************** Hover card effect ends *************** */

/* card border  */

.lineup-card-h2-border {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 0;
}

.box {
  height: 50px;
  width: 90%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eee7d7;
}
.box__line {
  position: absolute;
  background: #eee7d7;
}
.box__line--top,
.box__line--bottom {
  height: 6px;
  width: 100%;
}
.box__line--top::before,
.box__line--bottom::before,
.box__line--top::after,
.box__line--bottom::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background: #eee7d7;
}
.box__line--top {
  top: -6px;
}
.box__line--top::before {
  left: 0;
  bottom: -6px;
}
.box__line--top::after {
  right: 0;
  bottom: -6px;
}
.box__line--bottom {
  bottom: -6px;
}
.box__line--bottom::before {
  left: 0;
  top: -6px;
}
.box__line--bottom::after {
  right: 0;
  top: -6px;
}
.box__line--left,
.box__line--right {
  width: 6px;
  height: 100%;
}
.box__line--left {
  left: -6px;
}
.box__line--right {
  right: -6px;
}
