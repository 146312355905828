/* Homepage css  */

.homepage-container {
  overflow: hidden;
  background-image: url("../../components/assets/DreamyDraw-24-Homepage-Assets/yellow-texture-2x.png");
}

.homepage-lineup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 6rem 0; */
  position: relative;
}

.homepage-lineup>img {
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 2;
}

/* @media screen and (min-width: 1000px) {
  .homepage-lineup>img {
    width: 50%;
  }
} */


/* Homepage css end  */