/* Card section css  */

.ticket-box__line {
  position: absolute;
  background: #f05e22;
}
.ticket-box__line--top,
.ticket-box__line--bottom {
  height: 6px;
  width: 100%;
}
.ticket-box__line--top::before,
.ticket-box__line--bottom::before,
.ticket-box__line--top::after,
.ticket-box__line--bottom::after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background: #c19702;
}
.ticket-box__line--top {
  top: -6px;
}
.ticket-box__line--top::before {
  left: 0;
  bottom: -6px;
}
.ticket-box__line--top::after {
  right: 0;
  bottom: -6px;
}
.ticket-box__line--bottom {
  bottom: -6px;
}
.ticket-box__line--bottom::before {
  left: 0;
  top: -6px;
}
.ticket-box__line--bottom::after {
  right: 0;
  top: -6px;
}
.ticket-box__line--left,
.ticket-box__line--right {
  width: 6px;
  height: 100%;
}
.ticket-box__line--left {
  left: -6px;
}
.ticket-box__line--right {
  right: -6px;
}

/* card border ends */


.ticket-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5rem;
  height: max-content;
  position: relative;
  background-image: url("../../components/assets/DreamyDraw-24-Homepage-Assets/yellow-texture-2x.png");
  
}

.ticket-post {
  display: flex;
  align-items: center;
  margin: 3rem 1rem;
  /* height: 400px; */
  width: 80%;
  position: relative;
}

.ticket-post__img {
  max-width: 60%;
  height: 25rem;
  padding: 0 2rem;
  position: relative;
}

.ticket-post__img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.ticket-post__info {
  width: 60%;
}

.ticket-post__title {
  font-size: clamp(20px, 3vw, 2rem);
  margin: 4rem 0 1rem;
  text-transform: uppercase;
  color: #f05e22;
}

.ticket-post__text {
  margin-bottom: 3rem;
  font-size: clamp(16px, 3vw, 1.2rem);
  color: rgba(0, 0, 0, 0.7);
  list-style: none;
}

.ticket-post__li {
    margin: 0;
    margin-bottom: 1em;
    padding-left: 1.5em;
    position: relative;
    
    &:after {
      content: '';
      height: .4em;
      width: .4em;
      background: #008080;
      display: block;
      position: absolute;
      transform: rotate(45deg);
      top: .5em;
      left: 0;
    }
    
  }

.discount-tickets-link {
  font-size: clamp(16px, 3vw, 1rem);
  margin: 1rem;
  color: #cc4442;
  text-decoration: none;
}

.discount-tickets-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.discount-tickets-link > h3 {
  font-size: clamp(16px, 3vw, 1rem);
}

@media screen and (max-width: 1068px) {
  .ticket-post {
    flex-direction: column;
    width: auto;
    height: auto;
    margin-top: 5rem;
  }

  .ticket-post__img {
    max-width: 80%;
    height: 25rem;
    padding: 0 2rem;
    transform: translateY(-7rem);
    position: relative;
  }

  .ticket-post__info {
    padding: 0 2rem;
  }

  .ticket-post__title {
    font-size: clamp(20px, 3vw, 2rem);
    margin: 0 0 1rem;
    text-transform: uppercase;
    color: black;
  }

  .ticket-post__text {
    margin-bottom: 1rem;
    font-size: clamp(16px, 3vw, 1.2rem);
    color: rgba(0, 0, 0, 0.7);
  }

  .ticket-post__info {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .ticket-container {
    padding: 0;
  }

  .ticket-post {
    margin-top: 0;
  }

  .ticket-post__info {
    width: 100%;
  }

  .ticket-post__img {
    max-width: 100%;
    height: 17rem;
    margin: 2rem 0;
    transform: translate(0);
  }
}

/* Card section End */

/* tickets btn  */

.tickets-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
}

.two-img {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 0 1rem;
}

.third-img {
  /* width: 60%; */
  margin: 1rem;
}

@media screen and (max-width: 530px) {
  .tickets-button-container > * {
    width: 50%;
    cursor: pointer;
    flex-wrap: wrap;
  }

  .third-img {
    margin: 0 0 1rem 0;
  }
}
