.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 22;
  margin: 0 auto;
  width: 80%;
  background-image: url("../../components/assets/DreamyDraw-24-Homepage-Assets/yellow-texture-2x.png");

}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu {
  display: none;
}

.menu-icon {
  cursor: pointer;
  display: block;
}

.menu ul {
  list-style: none;
  display: block;
}

.menu ul li {
  padding: 2rem;
}

.menu ul li a {
  color: #F1E5D7;
  font-size: clamp(2.8rem, 4vw, 4rem);
  text-decoration: none;
  font-family: Clamiroe-texture, "sans-serif";
  margin-right: 15px;
}

.menu ul li a:hover {
  color: #2588c9;
}

.menu.open {
  position: fixed;
  top: 0;
  left: 0;
  width: 40%;
  height: 100vh;
  background-color: #f05e22;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.navbar-header-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

a.navbar-btn {
  display: contents;
}

.navbar-btn > img {
  width: clamp(100px, 20%, 10%);
}

.navbar-header-img > img {
  width: 80%;
}

.close-icon {
  position: absolute;
  top: 50px;
  right: 50px;
  font-size: 20px;
  cursor: pointer;
}

@media screen and (max-width: 999px) {
  .menu.open {
    width: 100%;
  }

  .main-container {
    width: 90%;
    flex-direction: column;
  }

  .navbar-content {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 2.5rem;
    /* visibility: hidden; new */
  }

  .navbar-header-img {
    margin: 1rem !important;
  }
}

@media screen and (min-width: 999px) {
  .navbar-content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    /* visibility: hidden; new */
  }
}



/* border img  */
.dd-border-img-div {
  display: flex;
  justify-content: start;
  align-items: start;
}