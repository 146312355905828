/* Partners page css  */

.partners-backimg > img {
  max-height: 70vh;
  min-height: 70vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: -1;
}

.partners-backimg > video {
  max-height: 70vh;
  min-height: 70vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: -1;
}

.partners-h1 h1 {
  position: absolute;
  top: 20%;
  left: 25%;
}

.partnersDiv {
  position: relative;
  display: flex;
  flex-direction: column;
}

.partnersDiv > img {
  width: 500px;
  height: 200px;
  margin: 4rem auto;
}

.partnersDiv > h1 {
  padding-top: 40px;
  font-size: clamp(2rem, 4vw, 4rem);
  text-align: center;
  color: white;
}

.partnersDiv > h6 {
  font-size: clamp(50px, 4vw, 8rem);
  color: white;
  text-align: center;
}

.partnersDiv p {
  margin: 6rem;
  color: white;
  text-align: center;
  letter-spacing: 2px;
  font-size: clamp(20px, 7vw, 6rem);
  font-family: var(--text-tall);
}

@media screen and (max-width: 651px) {
  .partnersDiv img {
    width: 300px;
    height: 124px;
  }

  .partnersDiv p {
    margin: 10px 2rem;
  }
}

/* Partners page css End */

/* Partners logo Page  */

.partners-slider-area {
  margin: 4rem 0;
}

.partners-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.partners-item {
  margin: 2rem;
}

.partners-item > img {
  border-radius: 15px;
  height: 95px;
}

.partners-logo-h2 {
  color: #ffffff;
  font-size: clamp(4rem, 5vw, 5rem);
  text-align: center;
  font-family: var(--text-tall);
}