.footer-container {
  height: max-content;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: #55a49e;
  background-image: url("../../components/assets/DreamyDraw-24-Homepage-Assets/blue-texture-2x.png");
}

.footer-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 0 0;
}

.footer-icons {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  align-items: center;
}

.footer-icons > a {
  color: #f4e9d8;
  font-size: 30px;
}

.footer-icons > a:hover {
  color: #2588c9;
}

.footer-icons-seprator {
  display:flex;
  align-items: center;
  width: 60%;
}

.footer-icons-seprator .footer-separator-line{
  height: 3px;
  flex: 1;
  background-color: #f4e9d8;
}


.footer-links {
  display: flex;
  gap: 3rem;
  text-decoration: none;
  margin: 2rem;
}

.footer-links > a {
  text-decoration: none;
  color: #f4e9d8;
  font-family: Clamiroe-texture;
}

.footer-links > a:hover {
  color: #2588c9;
}

.footer-copyright {
  font-size: 16px;
  margin-bottom: 0.5rem;
  color: #f4e9d8;
  font-family: Sonder-Sans;
}

@media screen and (max-width: 768px) {
  .footer-icons {
    gap: 3rem;
  }

  .footer-links {
    flex-direction: column;
    gap: 1rem;
  }
}
