.tickets-header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1rem auto;
  object-fit: cover;
  overflow: hidden;
  background-image: url("../../components/assets/DreamyDraw-24-Homepage-Assets/yellow-texture-2x.png");
}

.tickets-header-img {
  background-position: center;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 2;
}