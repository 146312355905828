.lineup-element-cover {
  /* background: linear-gradient(to bottom, #780096, #F05C1C, #1abc9c); */
  overflow: hidden;
  position: relative;
  background-image: url("../../components/assets/DreamyDraw-24-Homepage-Assets/yellow-texture-2x.png");
}

.lineup-container {
  max-height: 70vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.lineup-card-display {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.daily-lineup,
.schedule-lineup {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 7rem 0;
  position: relative;
}

.daily-lineup > img {
  height: auto;
  width: 50vw;
}

.schedule-lineup > img {
  height: auto;
  width: 50vw;
  display: flex;
  margin: 20px;
  padding: 5px;
}

/* schedule and daily-lineup h3  */

.schedule-lineup > h3,
.daily-lineup > h3 {
  font-size: clamp(2rem, 6vw, 4rem);
  color: #ffedc9;
  text-align: center;
}

@media screen and (max-width: 768px) {

  .schedule-lineup > img {
    width: 100vw;
  }
}

/* lineup button css  */

.lineup-filter-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin: 1.5rem 0;
}

.lineup-button {
  width: 14%;
  cursor: pointer;
}

/* lineup button css End  */

/* Lineup image  */

.lineup-header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1rem auto;
  object-fit: cover;
  overflow: hidden;
  background-image: url("../../components/assets/DreamyDraw-24-Homepage-Assets/yellow-texture-2x.png");
}

.lineup-backimg {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.lineup-header-border {
  width: 100%;
}

@media screen and (max-width: 999px) {
  .lineup-filter-btns {
    flex-direction: column;
  }
  .lineup-header-border {
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .lineup-button {
    width: 32%;
  }
}
