.each-lineup-container {
  position: relative;
  background-image: url("../../components/assets/DreamyDraw-24-Homepage-Assets/yellow-texture-2x.png");
}

.each-lineup-go-back {
  color: #fff;
  font-size: 2rem;
  margin: 2rem;
}

.each-single-page-container {
  padding: 1rem;
  height: max-content;
  display: block;
  justify-content: center;
  align-items: center;
  position: relative;
}

.each-page-mockup {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.each-page-img {
  max-height: 600px;
}

.each-page-right h1 {
  font-size: clamp(30px, 15vw, 3rem);
  color: #000000;
  text-align: center;
  padding-top: 2rem;
}

.each-page-right p {
  font-size: clamp(15px, 4vw, 1.2rem);
  padding: 2rem;
  line-height: 2;
  color: #000000;
}

.each-page-right-social {
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 6rem;
}

.each-page-right-social > a {
  font-size: 2rem;
  color: #000000;
}

.spotify-player {
  border-radius: 15px;
  padding: 1rem 0;
}

.each-page-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 1290px) {
  .each-single-page-container {
    display: block;
    height: max-content;
  }

  .each-page-left {
    width: 100%;
  }

  .each-page-right {
    width: 100%;
  }

  .custom-shape-divider-bottom-1659732424 svg {
    display: none;
  }
}

.artistImage {
  float: left;
  height: 500px;
  width: 420px;
}
