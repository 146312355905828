/* 

temp

#e2d1bb
#353739
#CC4442
#2588C9

 */

.faqs-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* background: linear-gradient(to bottom, #780096, #F05C1C); */
}

.dd-map {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  margin-top: 3rem;
}

.faqs-header {
  height: 30vh;
  color: #000000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faqs-header>h1 {
  font-size: 4rem;
}

.faqs-container {
  width: 100%;
  padding: 5rem 2rem;

}

.faqs-container > h1 {
  color: #000000;
  text-align: left;
}

.faqs-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  /* background: rgb(200, 200, 200); */
  background: #CC4442;
  margin-bottom: 8px;
  border-radius: 8px;
  text-align: left;
  text-transform: none;
  overflow: hidden;
  height: auto;
  padding: 16px;
  margin-top: 1rem;
}

.faqs-question>h1 {
  padding: 1rem;
  font-size: 1.3rem;
  color: #ffffff;
}


.faqs-answer {
  background: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  border-radius: 8px;
}

.faqs-answer>p {
  font-size: 1.3rem;
  color: #CC4442;
}
/* 
.faqs-answer:hover p {
  color: #CC4442;
  transition: all .7s;
} */