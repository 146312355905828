.text-updates-main {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    flex-direction: row;
    width: 100%;
    height: 22vh;
    background: #55a49e;
    margin-bottom: 2rem;
  background-image: url("../../components/assets/DreamyDraw-24-Homepage-Assets/blue-texture-2x.png");

  }
  
  .text-updates-signup {
    font-size: clamp(1.2rem, 3vw, 3rem);
    text-align: center;
    color: #f4e9d8;
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }
  
  .text-updates-input > form {
    display: flex;
    align-items: center;
  }
  
  .text-updates-input > input {
    padding: 1rem;
    width: 20rem;
    border-radius: 8px;
    outline: none;
    border: none;
  }
  
  .text-updates-input > button {
    margin-left: 1rem;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: #f05e22;
    color: #ffffff;
  }
  
  .text-updates-input > button:hover {
    cursor: pointer;
  }
  
  @media screen and (max-width: 1068px) {
    .text-updates-main {
      flex-direction: column;
      gap: 1rem;
      height: 18vh;
    }
  }
  
  @media screen and (max-width: 768px) {
    .text-updates-input > input {
      padding: .7rem;
      width: 10rem;
      border-radius: 8px;
      outline: none;
      border: none;
    }

    .text-updates-main {
      height: 14vh;
      gap: 0;
      justify-content: space-evenly;
    }
  }

  @media screen and (max-width: 295px) {

    .text-updates-input {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
    }
  }
  