.diamond-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 100%;
    padding: 20px 0;
  }
  
  .diamond {
    position: relative;
    width: 20px; /* Adjust the width as needed */
    height: 20px; /* Adjust the height as needed */
    background-color: transparent;
  }
  
  .diamond::before,
  .diamond::after {
    content: "";
    position: absolute;
    width: 20px; /* Adjust the width as needed */
    height: 20px; /* Adjust the height as needed */
    background-color: #008080; /* Diamond color */
  }
  
  .diamond::before {
    top: 0;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  
  .diamond::after {
    top: 0;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
  }
  
  .title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  

  .diamond-h2 {
    font-size: clamp(34px, 3.5vw, 3.5rem);
    text-transform: uppercase;
    color: #f05e22;
  }